import { Box } from '@mui/material'
import { StyledTipsDarkIcon, StyledTipsLightIcon } from 'components/Icons/StyledIcon'
import Tooltip from 'components/Tooltip'
import { TooltipBox } from 'pages/Liquidity/LiquidityRow'
import { useState } from 'react'
import { useTheme } from 'styled-components/macro'
import { ThemedText } from 'theme'

import { ReactComponent as NoLiquityIcon } from '../../assets/svg/noliquity_token.svg'
export default function Tips({ tipsStr }: { tipsStr?: any }) {
  const theme = useTheme()
  const [showHover_tips, setShowHover_tips] = useState(false)

  return (
    <Tooltip
      width="300px"
      show={showHover_tips}
      text={
        <TooltipBox>
          <div className="tooltipItem">
            <ThemedText.TextSecondary fontSize={14}>{tipsStr}</ThemedText.TextSecondary>
          </div>
        </TooltipBox>
      }
    >
      <Box onMouseEnter={() => setShowHover_tips(true)} onMouseLeave={() => setShowHover_tips(false)}>
        {theme.darkMode ? <StyledTipsDarkIcon /> : <StyledTipsLightIcon />}
      </Box>
    </Tooltip>
  )
}

export function NoLiquityTips() {
  const [showHover_tips, setShowHover_tips] = useState(false)

  return (
    <Tooltip
      width="300px"
      show={showHover_tips}
      text={
        <TooltipBox>
          <div className="tooltipItem">
            <ThemedText.TextSecondary fontSize={14}>
              This token has low liquidity, making it highly volatile to price movements on low trading volume. Rewards
              values displayed may not be accurate due to low liquidity depth.
            </ThemedText.TextSecondary>
          </div>
        </TooltipBox>
      }
    >
      <Box onMouseEnter={() => setShowHover_tips(true)} onMouseLeave={() => setShowHover_tips(false)}>
        <NoLiquityIcon style={{ marginLeft: '4px' }} />
      </Box>
    </Tooltip>
  )
}
