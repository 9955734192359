import Column from 'components/Column'
import styled from 'styled-components/macro'

import SettingsMenu from './SettingsMenu'

const DefaultMenuWrap = styled(Column)`
  width: 100%;
  height: 100%;
`

interface DefaultMenuProps {
  toggleWalletDrawer: () => void
}

function DefaultMenu({ toggleWalletDrawer }: DefaultMenuProps) {
  return (
    <DefaultMenuWrap>
      {/* {menu === MenuState.DEFAULT &&
        (isAuthenticated ? (
          <AuthenticatedHeader account={account} openSettings={openSettings} />
        ) : (
          <WalletModal openSettings={openSettings} />
        ))} */}
      <SettingsMenu onClose={toggleWalletDrawer} />
    </DefaultMenuWrap>
  )
}

export default DefaultMenu
