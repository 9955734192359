import { providers } from 'ethers/lib'
import React from 'react'
import { type HttpTransport } from 'viem'
import { PublicClient, useAccount, useNetwork, usePublicClient, useWalletClient, WalletClient } from 'wagmi'
function publicClientToProvider(publicClient: PublicClient) {
  const { chain, transport } = publicClient
  const network = {
    chainId: chain.id,
    name: chain.name,
    ensAddress: chain.contracts?.ensRegistry?.address,
  }
  if (transport.type === 'fallback')
    return new providers.FallbackProvider(
      (transport.transports as ReturnType<HttpTransport>[]).map(
        ({ value }) => new providers.JsonRpcProvider(value?.url, network)
      )
    )

  return new providers.JsonRpcProvider(transport.url, network)
}

function walletClientToSigner(walletClient: WalletClient) {
  const { chain, transport } = walletClient
  const network = {
    chainId: chain.id,
    name: chain.name,
    ensAddress: chain.contracts?.ensRegistry?.address,
  }
  const provider = new providers.Web3Provider(transport, network)
  return provider
}

function walletClientToProviderSigner(walletClient: WalletClient) {
  const { account, chain, transport } = walletClient
  const network = {
    chainId: chain.id,
    name: chain.name,
    ensAddress: chain.contracts?.ensRegistry?.address,
  }
  const provider = new providers.Web3Provider(transport, network)
  const signer = provider.getSigner(account.address)
  return signer
}

function useEthersProvider({ chainId }: { chainId?: number } = {}) {
  const publicClient = usePublicClient({ chainId })
  return React.useMemo(() => publicClientToProvider(publicClient), [publicClient])
}

export function useEthersSignerProvider() {
  const { chain } = useNetwork()
  const chainId = chain?.id

  const { data: walletClient } = useWalletClient({ chainId })
  return React.useMemo(() => (walletClient ? walletClientToSigner(walletClient) : undefined), [walletClient])
}

export function useEthersSigner({ chainId }: { chainId?: number } = {}) {
  const { data: walletClient } = useWalletClient({ chainId })
  return React.useMemo(() => (walletClient ? walletClientToProviderSigner(walletClient) : undefined), [walletClient])
}

export const useActiveChainId = () => {
  const { chain, chains } = useNetwork()
  const chainId = chain?.id
  const isNetwork = chains.filter((x) => x.id === chainId).length === 0
  const { address } = useAccount()

  const singerProvider = useEthersSignerProvider()
  const publicProvider = useProvider({ chainId })

  return {
    chainIdError: isNetwork,
    chainId: chainId ?? 168587773,
    provider: singerProvider,
    account: address as string | undefined,
    publicProvider,
  }
}

export const useProvider = ({ chainId }: { chainId?: number }) => {
  // const provder = useSyncExternalStoreWithSelector(
  //   (cb) => watchPublicClient({ chainId }, cb),
  //   () => getPublicClient({ chainId }),
  //   () => getPublicClient({ chainId }),
  //   (x) => x,
  //   () => {
  //     return true
  //   }
  // )
  return useEthersProvider({ chainId })
}
