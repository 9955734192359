import styled from 'styled-components/macro'

export const Cell = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const TableBorder = styled.div`
  background-color: transparent;
  border-bottom: 1px solid;
  border-color: ${({ theme }) => theme.primaryBorder};
  width: 100%;
`

export const StyledTableRow = styled(TableBorder)<{ $padding?: string }>`
  display: grid;
  padding: ${({ $padding }) => ($padding ? $padding : '16px 0')};
  word-break: break-word;
`

export const TableDataContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`
