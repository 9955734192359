import { Trans } from '@lingui/macro'
import { useMediaQuery } from '@mui/material'
import { ConnectButton } from '@rainbow-me/rainbowkit'
import { InterfaceEventName } from '@uniswap/analytics-events'
import { sendAnalyticsEvent } from 'analytics'
import { useAccountDrawer } from 'components/AccountDrawer'
import { ButtonPrimary } from 'components/Button'
import { StyledDropDown, StyledSetting } from 'components/Icons/StyledIcon'
import TransactionConfirmationModal from 'components/TransactionConfirmationModal'
import Web3Status from 'components/Web3Status'
import { useActiveChainId } from 'connection/useActiveChainId'
import { useFAUCET } from 'hooks/useContract'
import { Box } from 'nft/components/Box'
import { Row } from 'nft/components/Flex'
import { useCallback, useState } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import { useTransactionAdder } from 'state/transactions/hooks'
import { TransactionType } from 'state/transactions/types'
import styled, { useTheme } from 'styled-components/macro'
import { BREAKPOINTS, ThemedText } from 'theme'
import { shortenAddress } from 'utils'
import { handlerError } from 'utils/formatError'

import { ReactComponent as LogoDarkIcon } from '../../assets/imgs/Logo_bbeta.svg'
import { ReactComponent as LogoMIcon } from '../../assets/imgs/logo_mbeta.svg'
import { ReactComponent as LogoIcon } from '../../assets/imgs/Logo_wbeta.svg'
import Blur from './Blur'
import NavMenuTabs from './NavMenuTabs'
import * as styles from './style.css'

const Nav = styled.nav`
  padding: ${({ theme }) => `${theme.navVerticalPad}px 12px`};
  width: 100%;
  height: ${({ theme }) => theme.navHeight}px;
  z-index: 2;
`

const Logo = styled(LogoIcon)`
  #rogue {
    fill: ${({ theme }) => theme.logoPath};
  }
`

const LogoM = styled(LogoMIcon)``

const ResponsiveButtonPrimary = styled(ButtonPrimary)`
  border-radius: 12px;
  font-size: 16px;
  padding: 6px 8px;
  width: fit-content;
  ${({ theme }) => theme.deprecated_mediaWidth.deprecated_upToSmall`
    flex: 1 1 auto;
  `};
`

const StyledSettingBox = styled(Box)`
  width: 36px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.primaryBorder};
  cursor: pointer;
`

const StyledNetworkBox = styled(Row)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 36px;
  padding: 10px 16px;
  gap: 10px;
  flex-shrink: 0;
  border: 1px solid ${({ theme }) => theme.primaryBorder};
  border-radius: 8px;
  @media screen and (max-width: ${BREAKPOINTS.lg}px) {
    padding: 10px 8px;
  }
`

const StyledNetwork = styled(Row)`
  gap: 10px;
  white-space: nowrap;
  cursor: pointer;
  &:hover {
    background: linear-gradient(180deg, #1ea7e4 0%, #01bbbe 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
`

const StyledAddress = styled(StyledNetwork)`
  text-decoration-line: underline;
  text-decoration-color: ${({ theme }) => theme.textPrimary};
  &:hover {
    text-decoration-color: #1ea7e4;
  }
`

const Navbar = ({ blur }: { blur: boolean }) => {
  const { account } = useActiveChainId()
  const navigate = useNavigate()

  const [attemptingTxn, setAttemptingTxn] = useState<boolean>(false)
  const [txHash, setTxHash] = useState<string>('')
  const [txError, setTxError] = useState<string>('')
  const pendingText = 'pending ...'
  const addTransaction = useTransactionAdder()

  const [isOpen, setIsOpen] = useState<boolean>(false)

  const onDismiss = () => {
    setIsOpen(false)
    setAttemptingTxn(false)
    setTxHash('')
    setTxError('')
  }
  const feact = useFAUCET()

  const handelFaucet = useCallback(async () => {
    if (!feact || !account) return

    setIsOpen(true)
    setAttemptingTxn(true)
    try {
      const response = await feact.claim()
      setAttemptingTxn(false)

      addTransaction(response, {
        type: TransactionType.CLAIM,
        recipient: account,
        uniAmountRaw: '0',
      })
      setTxHash(response.hash)
    } catch (error) {
      setAttemptingTxn(false)
      setTxError(handlerError(error))
      console.log('faucet error', error, handlerError(error))
    }
  }, [account, addTransaction, feact])

  const [accountDrawerOpen, toggleAccountDrawer] = useAccountDrawer()

  const handleLogoIconClick = useCallback(() => {
    if (accountDrawerOpen) {
      toggleAccountDrawer()
    }
    navigate({
      pathname: '/',
      // search: '?intro=true',
    })
  }, [accountDrawerOpen, navigate, toggleAccountDrawer])

  const isSmallScreen = useMediaQuery(`(max-width: ${BREAKPOINTS.lg}px)`)
  const isSmallScreen2 = useMediaQuery(`(max-width: ${BREAKPOINTS.md}px)`)
  const isSmallScreen3 = useMediaQuery(`(max-width: ${BREAKPOINTS.sm}px)`)

  const handleWalletDropdownClick = useCallback(() => {
    sendAnalyticsEvent(InterfaceEventName.ACCOUNT_DROPDOWN_BUTTON_CLICKED)
    toggleAccountDrawer()
  }, [toggleAccountDrawer])
  const theme = useTheme()
  return (
    <>
      {blur && <Blur />}
      <Nav>
        <Box display="flex" justifyContent="space-between" height="full" flexWrap="nowrap">
          <Box className={styles.leftSideContainer}>
            <Box
              className={styles.logoContainer}
              style={{ marginRight: isSmallScreen ? '16px' : '32px' }}
              onClick={handleLogoIconClick}
            >
              {isSmallScreen3 ? (
                <LogoM data-testid="roguex-logo" className={styles.logo} />
              ) : theme.darkMode ? (
                <LogoDarkIcon data-testid="roguex-logo" className={styles.logo} />
              ) : (
                <Logo data-testid="roguex-logo" className={styles.logo} />
              )}
            </Box>

            {!isSmallScreen && <NavMenuTabs />}
          </Box>
          {/* <Box display="flex" alignItems="center">
            {!isSmallScreen && <PageTabs />}
          </Box> */}
          <Box className={styles.rightSideContainer}>
            {/* {!isSmallScreen2 && (
              <ResponsiveButtonPrimary height="36px" onClick={handelFaucet} data-cy="faucet-button" id="faucet-button">
                <Trans>Faucet</Trans>
              </ResponsiveButtonPrimary>
            )} */}
            <Row gap={isSmallScreen2 ? '8' : '12'}>
              <Web3Status />
              <ConnectButton.Custom>
                {({ account, chain, openChainModal, openConnectModal, authenticationStatus, mounted }) => {
                  // console.log('account', account)
                  // Note: If your app doesn't use authentication, you
                  // can remove all 'authenticationStatus' checks
                  const ready = mounted && authenticationStatus !== 'loading'
                  const connected =
                    ready && account && chain && (!authenticationStatus || authenticationStatus === 'authenticated')

                  return (
                    <div
                      {...(!ready && {
                        'aria-hidden': true,
                        style: {
                          opacity: 0,
                          pointerEvents: 'none',
                          userSelect: 'none',
                        },
                      })}
                    >
                      {(() => {
                        if (!connected) {
                          return (
                            <StyledNetworkBox onClick={openConnectModal} style={{ cursor: 'pointer' }}>
                              <ThemedText.TextPrimary fontWeight={700}>CONNECT WALLET</ThemedText.TextPrimary>
                            </StyledNetworkBox>
                          )
                        }

                        if (chain.unsupported) {
                          return (
                            <StyledNetworkBox onClick={openChainModal} style={{ cursor: 'pointer' }}>
                              <ThemedText.TextSecondary>Wrong network</ThemedText.TextSecondary>
                            </StyledNetworkBox>
                          )
                        }

                        return (
                          <StyledNetworkBox>
                            <StyledNetwork onClick={openChainModal}>
                              {chain.hasIcon && chain.iconUrl && (
                                <img
                                  alt={chain.name ?? 'Chain icon'}
                                  src={chain.iconUrl}
                                  style={{
                                    width: 24,
                                    height: 24,
                                    borderRadius: '50%',
                                  }}
                                />
                              )}
                              {!isSmallScreen2 && (
                                <div className="flex-1">
                                  <ThemedText.TextPrimary fontWeight={700}>
                                    <Trans>{chain.name}</Trans>
                                  </ThemedText.TextPrimary>
                                </div>
                              )}
                            </StyledNetwork>
                            <StyledDropDown />
                            <ThemedText.TextSecondary fontSize={14} fontWeight={700}>
                              |
                            </ThemedText.TextSecondary>
                            <NavLink to="/profile" style={{ textDecoration: 'none' }}>
                              <StyledAddress
                                // onClick={openAccountModal}
                                type="button"
                              >
                                <ThemedText.TextPrimary fontWeight={700}>
                                  <Trans>{shortenAddress(account?.address)}</Trans>
                                </ThemedText.TextPrimary>
                              </StyledAddress>
                            </NavLink>
                          </StyledNetworkBox>
                        )
                      })()}
                    </div>
                  )
                }}
              </ConnectButton.Custom>
              {/* {!isSmallScreen && <NavMenuTabs />} */}
              {!isSmallScreen && (
                <StyledSettingBox data-testid="settings" onClick={handleWalletDropdownClick}>
                  <StyledSetting />
                </StyledSettingBox>
              )}
            </Row>
          </Box>
        </Box>
        <TransactionConfirmationModal
          isOpen={isOpen}
          onDismiss={onDismiss}
          attemptingTxn={attemptingTxn}
          hash={txHash}
          txError={txError}
          width="400px"
          reviewContent={() => <></>}
          pendingText={pendingText}
        />
      </Nav>
    </>
  )
}

export default Navbar
